import keyMirror from 'keymirror';

const CordovaPageConstants = {
  about: null,
  ballotLgHdrWild: null,
  ballotSmHdrWild: null,
  ballotVote: null,
  candidate: null,
  candidateWild: null,
  friends: null,
  friendsCurrent: null,
  friendsSentRequest: null,
  start: null,
  measureWild: null,
  moreAbout: null,
  moreCredits: null,
  moreElections: null,
  moreFaq: null,
  moreTerms: null,
  moreTools: null,
  news: null,
  officeWild: null,
  opinions: null,
  opinionsFiltered: null,
  ready: null,
  signInOptions: null,
  settingsAccount: null,
  settingsHamburger: null,
  settingsNotifications: null,
  settingsProfile: null,
  settingsSubscription: null,
  settingsVoterGuideLst: null,
  settingsWild: null,
  twitterHandleLanding: null,
  twitterIdMFollowers: null,
  twitterInfoPage: null,
  twitterSignIn: null,
  valuesWild: null,
  values: null,
  valuesList: null,
  voterGuideCreatorWild: null,
  voterGuideWild: null,
  welcomeWild: null,
  wevoteintroWild: null,
  defaultVal: null,
};

export default keyMirror(CordovaPageConstants);
