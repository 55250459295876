import keyMirror from 'keymirror';

const FacebookConstants = {
  FACEBOOK_LOGIN_CHANGE: null,
  FACEBOOK_GETTING_PICTURE: null,
  FACEBOOK_RECEIVED_PICTURE: null,
  FACEBOOK_RECEIVED_INVITABLE_FRIENDS: null,
  FACEBOOK_READ_APP_REQUESTS: null,
  FACEBOOK_DELETE_APP_REQUEST: null,
  FACEBOOK_LOGGED_IN: null,
  FACEBOOK_LOGGED_OUT: null,
  FACEBOOK_RECEIVED_DATA: null,
  FACEBOOK_SIGN_IN_DISCONNECT: null,
  IMAGE_UPLOADED: null,
};

export default keyMirror(FacebookConstants);
